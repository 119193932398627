<template>
<div class="comp-container">
test_02
</div>
</template>
<script>
export default {
//props: {
//},
data() {
return {

}
},
created() {},
mounted() {},
//watch: {},
computed: {},
methods: {},
components: {
},
}
</script>
<style scoped>
.comp-container{
    background-color: rgb(208, 235, 135);
    padding: 20px;
    text-align: center;
}
</style>